'use client';
import { useEffect } from 'react';
import { useRouter, usePathname } from 'next/navigation';

export function useRedirectToDashboard(redirect: boolean) {
  const router = useRouter();
  const pathname = usePathname();

  console.debug('[useRedirectToDashboard] pathname:', pathname);

  useEffect(() => {
    if (redirect && pathname === '/') {
      router.push('/dashboard/workspaces');
    }
  }, [pathname, router, redirect]);
}
