import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import Select from 'react-select';
import { chains } from '#/providers/W3Provider/wagmiConfigs'; // Adjust the import path as necessary

export interface NetworkSwitcherProps {
  onNetworkChange?: (network: string) => void; // Optional, in case you want to handle additional logic
}

export const NetworkSwitcher: React.FC<NetworkSwitcherProps> = ({
  onNetworkChange,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedNetwork, setSelectedNetwork] = useState<string | null>(null);

  useEffect(() => {
    const network = Cookies.get('network');
    if (network) {
      setSelectedNetwork(network);
      setIsLoading(false);
    }
  }, []);

  const handleNetworkChange = (selectedOption: any) => {
    setIsLoading(true);
    const networkName = selectedOption.value;
    setSelectedNetwork(networkName);
    Cookies.set('network', networkName);
    if (onNetworkChange) {
      onNetworkChange(networkName);
    }
    window.location.reload(); // Reload the page to apply the network change
  };

  const options = chains.map((chain) => ({
    value: chain.network,
    label: (
      <div className="flex items-center">
        <img
          src={chain.iconUrl}
          alt={`${chain.name} logo`}
          className="h-4 w-4 mr-2"
        />
        {chain.name}
      </div>
    ),
  }));

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: '#1a1a1a',
      borderColor: 'transparent',
      boxShadow: state.isFocused ? '0 0 0 1px #555' : 'none', // Custom focus style
      '&:hover': {
        borderColor: '#555',
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: '#1a1a1a',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#333' : '#1a1a1a',
      color: state.isSelected ? '#fff' : '#ccc',
      '&:hover': {
        backgroundColor: '#555',
        color: '#fff',
      },
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: '#fff',
    }),
  };

  return (
    !isLoading && (
      <div className="">
        <Select
          styles={customStyles}
          value={options.find((option) => option.value === selectedNetwork)}
          onChange={handleNetworkChange}
          options={options}
          // classNamePrefix="react-select"
          className="bg-gray-900 rounded-[4px] h-full flex flex-row items-center justify-center p-0 text-white"
        />
      </div>
    )
  );
};

export default NetworkSwitcher;
