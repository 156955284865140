'use client';
import { Chain, connectorsForWallets } from '@rainbow-me/rainbowkit';
import {
  injectedWallet,
  metaMaskWallet,
  coinbaseWallet,
  // walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets';

import { configureChains, createClient } from 'wagmi';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { publicProvider } from 'wagmi/providers/public';
import { getDefaultProvider } from 'ethers';
import { mainnet as _mainnet, sepolia as _sepolia } from 'wagmi/chains';

import { rainbowMagicConnector } from './rainbowMagicConnector';

const base = {
  id: 8453,
  name: 'Base',
  network: 'base',
  iconUrl: 'https://docs.base.org/img/favicon.ico',
  nativeCurrency: {
    decimals: 18,
    name: 'Base',
    symbol: 'BASE',
  },
  rpcUrls: {
    default: {
      http: ['https://mainnet.base.org'],
    },
  },
};

// const baseSepolia = {
//   id: 84532,
//   name: 'Base Sepolia',
//   network: 'base-sepolia',
//   iconUrl: 'https://docs.base.org/img/favicon.ico',
//   testnet: true,
//   nativeCurrency: {
//     decimals: 18,
//     name: 'Base',
//     symbol: 'BASE',
//   },
//   rpcUrls: {
//     default: {
//       http: ['https://sepolia.base.org'],
//     },
//   },
// };

const mainnet = {
  ..._mainnet,
  iconUrl:
    'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/info/logo.png',
};

// const sepolia = {
//   ..._sepolia,
//   iconUrl:
//     'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/info/logo.png',
// };

const { chains, provider, webSocketProvider } = configureChains(
  [
    mainnet,
    base,
    // sepolia,
    // baseSepolia,
  ],
  [
    jsonRpcProvider({
      rpc: (chain) => ({
        http: chain.id === 1 ? 'https://rpc.ankr.com/eth' : '',
      }),
    }),
    alchemyProvider({
      apiKey: process.env.NEXT_PUBLIC_ALCHEMY_API_KEY,
    }),
    publicProvider(),
    // infuraProvider({
    //   apiKey: process.env.INFURA_API || '4863a01c109248f2b744bb3f69596f55',
    // }),
  ],
);

const connectors = connectorsForWallets([
  {
    groupName: 'Wallet/Web3:',
    wallets: [
      injectedWallet({ chains }),
      // rainbowWallet({ chains }),
      metaMaskWallet({ chains }),
      coinbaseWallet({ chains, appName: 'SimpFi.Ai' }),
      // walletConnectWallet({ chains }),
    ],
  },
]);

const customConnectors = connectorsForWallets([
  {
    groupName: 'Familiar Login:',
    wallets: [rainbowMagicConnector({ chains }) as unknown as Wallet],
  },
]);

const client = createClient({
  autoConnect: true,
  connectors: () => {
    // return [...connectors()];
    return [...customConnectors(), ...connectors()];
  },
  provider: Object.assign(getDefaultProvider(), { chains: chains }),
  // webSocketProvider,
});

const WagmiConfigs = {
  chains,
  provider,
  // webSocketProvider,
  connectors,
  customConnectors,
  client,
};

export { chains }; // Export chains for use in NetworkSwitcher
export default WagmiConfigs;
